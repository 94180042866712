import React from 'react';
import './rentnow.scss';
import { Link } from 'react-router-dom';
import cover from '../../../img/rent-now-cover.png';
import flow from '../../../img/rent-now-flow.png';
import result1 from '../../../img/result-1.png';
import result2 from '../../../img/result-2.png';
import result3 from '../../../img/result-3.png';
import result4 from '../../../img/result-4.png';
import proto1 from '../../../img/proto-1.mp4';
import proto2 from '../../../img/proto-2.mp4';
import rentporto from '../../../img/rent-porto.png';
import mockporto from '../../../img/mock-porto.png';
import dsporto from '../../../img/ds-porto.png';

export default function RentNow() {
    return (
        <div className="rent-now-cage" id='top-page'>
            <div className="text-center">
                <h2 className="porto-title">RentNow</h2>
                <div className='table-content'>
                    <div className='left-icon-group'>
                        <Link to='/porto' className='icon-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 4.25C0 3.64062 0.1875 3.125 0.65625 2.65625C1.07812 2.23438 1.59375 2 2.25 2H21.75C22.3594 2 22.875 2.23438 23.3438 2.65625C23.7656 3.125 24 3.64062 24 4.25V20.75C24 21.4062 23.7656 21.9219 23.3438 22.3438C22.875 22.8125 22.3594 23 21.75 23H2.25C1.59375 23 1.07812 22.8125 0.65625 22.3438C0.1875 21.9219 0 21.4062 0 20.75V4.25ZM10.875 4.25H2.53125C2.4375 4.25 2.39062 4.29688 2.34375 4.34375C2.25 4.4375 2.25 4.48438 2.25 4.53125V11.375H10.875V4.25ZM2.25 13.625V20.4688C2.25 20.5625 2.25 20.6094 2.34375 20.6562C2.39062 20.75 2.4375 20.75 2.53125 20.75H10.875V13.625H2.25ZM13.125 20.75H21.4688C21.5156 20.75 21.5625 20.75 21.6562 20.6562C21.7031 20.6094 21.75 20.5625 21.75 20.4688V13.625H13.125V20.75ZM21.75 11.375V4.53125C21.75 4.48438 21.7031 4.4375 21.6562 4.34375C21.5625 4.29688 21.5156 4.25 21.4688 4.25H13.125V11.375H21.75Z" fill="black"/>
                            </svg>
                        </Link>
                        <Link to='/porto/vcomds' className='icon-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.125 18.3125C9.03125 18.4531 8.89062 18.5 8.75 18.5C8.5625 18.5 8.46875 18.4531 8.375 18.3125L2.1875 12.125C2.04688 12.0312 2 11.9375 2 11.75C2 11.6094 2.04688 11.4688 2.1875 11.375L8.375 5.1875C8.46875 5.09375 8.5625 5 8.75 5C8.89062 5 9.03125 5.09375 9.125 5.1875L10.0625 6.07812C10.1562 6.21875 10.25 6.35938 10.25 6.5C10.25 6.6875 10.1562 6.78125 10.0625 6.875L6.26562 10.5312H22.4375C22.5781 10.5312 22.7188 10.625 22.8125 10.7188C22.9062 10.8125 23 10.9531 23 11.0938V12.4062C23 12.5938 22.9062 12.7344 22.8125 12.8281C22.7188 12.9219 22.5781 12.9688 22.4375 12.9688H6.26562L10.0625 16.625C10.1562 16.7188 10.25 16.8594 10.25 17C10.25 17.1875 10.1562 17.3281 10.0625 17.4219L9.125 18.3125Z" fill="black"/>
                            </svg>
                        </Link>
                    </div>
                    <div className='data-table'>
                        <div className='dt-item dt-label'><span>PROJECT</span></div>
                        <div className='dt-group dir-col'>
                            <div className='dt-item dt-data'>Booking.com Redesign Concept</div>
                            <div className='dt-group'>
                                <div className='dt-item dt-label'><span>SCOPE</span></div>
                                <div className='dt-item dt-data'>UX, UI, Interaction Design, & Prototyping</div>
                            </div>
                        </div>
                        <div className='dt-item dt-data'>2020</div>
                    </div>
                    <div className='right-icon-group'>
                        <Link to='/porto' className='icon-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.75 12.5L19.7656 17.5625C19.9062 17.7031 20 17.8906 20 18.0781C20 18.3125 19.9062 18.5 19.7656 18.5938L18.5938 19.7656C18.4531 19.9531 18.2656 20 18.0781 20C17.8438 20 17.6562 19.9531 17.5625 19.7656L12.5 14.75L7.4375 19.7656C7.29688 19.9531 7.10938 20 6.92188 20C6.6875 20 6.5 19.9531 6.40625 19.7656L5.23438 18.5938C5.04688 18.5 5 18.3125 5 18.0781C5 17.8906 5.04688 17.7031 5.23438 17.5625L10.25 12.5L5.23438 7.4375C5.04688 7.34375 5 7.15625 5 6.92188C5 6.73438 5.04688 6.54688 5.23438 6.40625L6.40625 5.23438C6.5 5.09375 6.6875 5 6.92188 5C7.10938 5 7.29688 5.09375 7.4375 5.23438L12.5 10.25L17.5625 5.23438C17.6562 5.09375 17.8438 5 18.0781 5C18.2656 5 18.4531 5.09375 18.5938 5.23438L19.7656 6.40625C19.9062 6.54688 20 6.73438 20 6.92188C20 7.15625 19.9062 7.34375 19.7656 7.4375L14.75 12.5Z" fill="black"/>
                            </svg>
                        </Link>
                        <Link to='/porto/mocktotype' className='icon-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.875 5.1875C15.9688 5.09375 16.0625 5 16.25 5C16.3906 5 16.5312 5.09375 16.625 5.1875L22.8125 11.375C22.9062 11.4688 23 11.6094 23 11.75C23 11.9375 22.9062 12.0312 22.8125 12.125L16.625 18.3125C16.5312 18.4531 16.3906 18.5 16.25 18.5C16.0625 18.5 15.9688 18.4531 15.875 18.3125L14.9375 17.4219C14.7969 17.3281 14.75 17.1875 14.75 17C14.75 16.8594 14.7969 16.7188 14.9375 16.625L18.7344 12.9688H2.5625C2.375 12.9688 2.23438 12.9219 2.14062 12.8281C2.04688 12.7344 2 12.5938 2 12.4062V11.0938C2 10.9531 2.04688 10.8125 2.14062 10.7188C2.23438 10.625 2.375 10.5312 2.5625 10.5312H18.7344L14.9375 6.875C14.7969 6.78125 14.75 6.6875 14.75 6.5C14.75 6.35938 14.7969 6.21875 14.9375 6.07812L15.875 5.1875Z" fill="black"/>
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className='main-content'>
                    <img src={cover} className='content-cover' />

                    <div className='text-group'>
                        <h6 className='content-title'>BACKGROUND</h6>
                        <p className='content-text'>Travelling apps are always my favourite to use as case studies. One of the biggest accommodation providers being used as a reference for this exercise. This company is famous for being data-driven when building and improving their product.</p>
                        <p className='content-text'>This concept purely tries to improve their visual app so it will be more compelling for the user to use the product. In this case, I also tried to give a new flow for the search room flow.</p>
                    </div>
                    <div className='text-group'>
                        <h6 className='content-title'>PROCESS</h6>
                        <p className='content-text'>Starting by trying out and learning how booking.com app works are the first part of the double diamond process trying to define the problem that I need to solve. I started to notice a couple of things that I thought could be improved.</p>
                        <p className='content-text'>
                            The main focus of this re-design exercise project are:
                            <ul>
                                <li>improve user flow for room searching</li>
                                <li>improve the visual style</li>
                            </ul>
                        </p>
                        <p className='content-text'>The first step is to try to figure out how to improve the room search user flow of the app. After a couple of different approaches to the solution for this, finally, try to go on with this flow which is trying to keep the flow more streamlined and keep the user focusing on the task.</p>
                    </div>

                    <img src={flow} className='content-img' />

                    <div className='text-group'>
                        <h6 className='content-title'>RESULT</h6>
                    </div>
                    
                    <div className='img-box'>
                        <img src={result1} />
                        <span>Hotel tab form (default)</span>
                    </div>

                    <div className='img-box'>
                        <img src={result2} />
                        <span>Hotel tab form (default)</span>
                    </div>

                    <div className='img-box'>
                        <img src={result3} />
                        <span>Hotel tab form (default)</span>
                    </div>

                    <div className='img-box'>
                        <img src={result4} />
                        <span>Hotel tab form (default)</span>
                    </div>

                    <div className='text-group'>
                        <h6 className='content-title'>PROTOTYPING</h6>
                    </div>

                    <div className='img-box'>
                        <video width="100%" height="auto" controls>
                            <source src={proto1} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <span>Home tab switch</span>
                    </div>

                    <div className='img-box'>
                        <video width="100%" height="auto" controls>
                            <source src={proto2} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <span>Search flow</span>
                    </div>

                    <div className='text-group'>
                        <h6 className='content-title'>CONCLUSION</h6>
                        <p className='content-text'>While I know I can’t test this output to see how well it performs in the real life situation, this is something that I deliver in terms of improving the visual style of the app.</p>
                        <p className='content-text'>Thank you for your time.</p>
                    </div>
                </div>
                <div className='footer-menu'>
                    <div className='portfolio-menu-group'>
                        <Link to='/porto/vcomds' className='portfolio-menu-item'>
                            <section className='img-box'>
                                <img src={dsporto} />
                            </section>
                            <section className='text-box'>
                                <h6 className='footer-menu-title'>Antlers Design System</h6>
                                <p className='footer-text'>Creating vaadin.com Design system</p>
                            </section>
                        </Link>
                        <Link to='/porto/mocktotype' className='portfolio-menu-item'>
                            <section className='img-box'>
                                <img src={mockporto} />
                            </section>
                            <section className='text-box'>
                                <h6 className='footer-menu-title'>Mocktotype</h6>
                                <p className='footer-text'>Simple app for displaying your mockup design</p>
                            </section>
                        </Link>
                    </div>
                    <div className='main-menu-bar'>
                        <ul>
                            <li>
                                <Link to='/'>INTRO</Link>
                            </li>
                            <li>
                                <Link to='/about/experience'>ABOUT ME</Link>
                            </li>
                            <li>
                                <Link to='/porto'>MY OTHER WORKS</Link>
                            </li>
                            {/* <li>
                                <Link>GET IN TOUCH</Link>
                            </li> */}
                        </ul>
                        <a className='scroll-top' href='#top-page'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.6875 9.125C5.54687 9.03125 5.5 8.89062 5.5 8.75C5.5 8.5625 5.54687 8.46875 5.6875 8.375L11.875 2.1875C11.9687 2.04687 12.0625 2 12.25 2C12.3906 2 12.5312 2.04687 12.625 2.1875L18.8125 8.375C18.9062 8.46875 19 8.5625 19 8.75C19 8.89062 18.9062 9.03125 18.8125 9.125L17.9219 10.0625C17.7812 10.1562 17.6406 10.25 17.5 10.25C17.3125 10.25 17.2187 10.1562 17.125 10.0625L13.4687 6.26562L13.4687 22.4375C13.4687 22.5781 13.375 22.7187 13.2812 22.8125C13.1875 22.9062 13.0469 23 12.9062 23L11.5937 23C11.4062 23 11.2656 22.9062 11.1719 22.8125C11.0781 22.7187 11.0312 22.5781 11.0312 22.4375L11.0312 6.26562L7.375 10.0625C7.28125 10.1562 7.14062 10.25 7 10.25C6.8125 10.25 6.67187 10.1562 6.57812 10.0625L5.6875 9.125Z" fill="black"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}