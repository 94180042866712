import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
  } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import './porto.scss';
import rentporto from '../../img/rent-porto.png';
import mockporto from '../../img/mock-porto.png';
import dsporto from '../../img/ds-porto.png';
import RentNow from './rentnow/rentnow.js';
import Mocktotype from './mocktotype/mocktotype.js';
import Vcomds from './vcomds/vcomds.js';
import list1 from '../../img/list-1.png';
import list2 from '../../img/list-2.png';
import list3 from '../../img/list-3.png';
import list4 from '../../img/list-4.gif';
import list5 from '../../img/list-5.gif';
import list6 from '../../img/list-6.png';
import list7 from '../../img/list-7.gif';
import list8 from '../../img/list-8.png';
import list9 from '../../img/list-9.png';
import list10 from '../../img/list-10.png';
import list11 from '../../img/list-11.png';
import list12 from '../../img/list-12.gif';
import list13 from '../../img/list-13.png';
import list14 from '../../img/list-14.png';
import list15 from '../../img/list-15.jpg';
import list16 from '../../img/list-16.jpg';
import list17 from '../../img/list-17.gif';

export default function Porto() {
  
    return (
      <div className="porto">
        <Route exact path="/porto">
          {({ match }) => (
          <CSSTransition in={match != null} timeout={{ appear: 500, enter: 500, exit: 500, }} appear exit classNames="porto-list" unmountOnExit>
            <div className="porto-cage">
              <div className="title-group">
                  <span class="bg-text">MY</span> 
                  <h3 class="chapter-title">WORKS</h3>
              </div>

              <h4 className='section-title'>Case study</h4>

              <ul className="porto-list cage">
                  <li className='box box-l-4 text-center'>
                      <Link to="porto/vcomds">
                        <img src={dsporto} />
                      </Link>
                      <h5 className='porto-item-title'>Antlers DS</h5>
                  </li>
                  <li className='box box-l-4 text-center'>
                      <Link to="porto/rentnow">
                        <img src={rentporto} />
                      </Link>
                      <h5 className='porto-item-title'>RentNow</h5>
                  </li>
                  <li className='box box-l-4 text-center'>
                      <Link to="porto/mocktotype">
                        <img src={mockporto} />
                      </Link>
                      <h5 className='porto-item-title'>Mocktotype</h5>
                  </li>
              </ul>
              <div className="work-example-block">
                <h4 className='section-title'>Work Example</h4>
                <ul className='work-example-list cage'>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/3644231-Quickpay-Widget'>
                      <img src={list1} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/3642334-Quickpay-Widget'>
                      <img src={list2} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/3623755--Freebie-Online-Transport-UI'>
                      <img src={list3} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/3623612-Online-Transport-UI-microinteractions-extended'>
                      <img src={list4} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/3596112-Online-Transport-UI-microinteractions'>
                      <img src={list5} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/3594862-Online-Transport-UI'>
                      <img src={list6} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/3573113-Dribbble-New-Feature-2'>
                      <img src={list7} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/3572594-Dribbble-New-Feature'>
                      <img src={list8} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/2526621-Profile'>
                      <img src={list9} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/2449572-sneak-peek'>
                      <img src={list10} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/2393808-Recipe-App'>
                      <img src={list11} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/2367211-Walkthrough-Auction'>
                      <img src={list12} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/2229082-Jasamarga'>
                      <img src={list14} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/2222925-404-page'>
                      <img src={list15} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/2131126-Mockup-Setting-Page'>
                      <img src={list16} />
                    </a>
                  </li>
                  <li className='box box-m-6 box-l-3'>
                    <a href='https://dribbble.com/shots/2102907-Mocktotype-Flow'>
                      <img src={list17} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </CSSTransition>
          )}  
        </Route>
        <Route exact path="/porto/rentnow">
          {({ match }) => (
            <CSSTransition in={match != null} timeout={{ appear: 500, enter: 500, exit: 500, }} appear exit classNames="porto-view" unmountOnExit>
              <div className="rentnow porto-item">
                <div className='transition-bg'></div>
                <RentNow/>
              </div>
            </CSSTransition>
          )}
        </Route>
        <Route exact path="/porto/mocktotype">
          {({ match }) => (
            <CSSTransition in={match != null} timeout={{ appear: 500, enter: 500, exit: 500, }} appear exit classNames="porto-view" unmountOnExit>
              <div className="porto-item">
                <div className='transition-bg'></div>
                <Mocktotype/>
              </div>
            </CSSTransition>
          )}
        </Route>
        <Route exact path="/porto/vcomds">
          {({ match }) => (
            <CSSTransition in={match != null} timeout={{ appear: 500, enter: 500, exit: 500, }} appear exit classNames="porto-view" unmountOnExit>
              <div className="porto-item">
                <div className='transition-bg'></div>
                <Vcomds/>
              </div>
            </CSSTransition>
          )}
        </Route>
      </div>
    );
  }