import React from 'react';
import { NavLink } from 'react-router-dom';
import Computer from './Computer.js';
import TextBox from './Text-box.js';
import './home.scss';
import imgprofile from '../../img/profile.png';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = { nameClass: '' };
    }

    componentWillUnmount() {
        this.setState({ nameClass: 'leaving' });
    }

    render() {
        return (
            <div className="home">
                {/* <Computer />
                <TextBox /> */}
                <div className="green-bg"></div>
                <div className="home-cage">
                    <div className="text-box">
                        <h1 className="main-title">I'm a designer with 9+ years of experience designing user centric and delightful digital products for web and mobile platforms.</h1>
                        <h4 className="sub-title">Currently, I'm living in Finland and work for one of the leading open-source Java web framework companies.</h4>
                        <ul className="home-nav">
                            <li>
                                <NavLink exact to='/porto'>SEE MY WORKS</NavLink>
                            </li>
                            <li>
                                <NavLink exact to='/about/experience'>LEARN ABOUT ME</NavLink>
                            </li>
                            {/* <li>
                                <a href="#">GET IN TOUCH</a>
                            </li> */}
                        </ul>
                    </div>
                    <div className="image-box">
                        <img src={imgprofile} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;