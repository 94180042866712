import React from 'react';
import './text-box.scss';

function calculate_age(dob) { 
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const TextBox = () => (
    <div className="text-box">
        <p>my name is <a href="#">Dion Pramadhan</a>.</p>
        <p>I am a UX/UI designer with {calculate_age(new Date(2011, 1, 1))}-years experience and currently working and living in Finland.</p>
        <div className="short-cut">
            <a href="/about">Read My Story</a>
            <span className="dot-separator"></span>
            <a href="#">View My Work</a>
        </div>
    </div>
);

export default TextBox;