import React from 'react';
import './mocktotype.scss';
import { Link } from 'react-router-dom';
import mock from '../../../img/mock-1.jpg';
import logoMock from '../../../img/logo-mock.jpg';
import mockIllust1 from '../../../img/mock-illust-1.jpg';
import mockIllust2 from '../../../img/mock-illust-2.jpg';
import mockIllust3 from '../../../img/mock-illust-3.jpg';
import mockIllust4 from '../../../img/mock-illust-4.jpg';
import mock1 from '../../../img/mock-1.png';
import mock2 from '../../../img/mock-2.png';
import mock3 from '../../../img/mock-3.png';
import mock4 from '../../../img/mock-4.png';
import mock5 from '../../../img/mock-5.png';
import rentporto from '../../../img/rent-porto.png';
import mockporto from '../../../img/mock-porto.png';
import dsporto from '../../../img/ds-porto.png';

export default function Mocktotype() {
    return (
        <div className="rent-now-cage" id='top-page'>
            <div className="text-center">
                <h2 className="porto-title">Mocktotype</h2>
                <div className='table-content'>
                    <div className='left-icon-group'>
                        <Link to='/porto' className='icon-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 4.25C0 3.64062 0.1875 3.125 0.65625 2.65625C1.07812 2.23438 1.59375 2 2.25 2H21.75C22.3594 2 22.875 2.23438 23.3438 2.65625C23.7656 3.125 24 3.64062 24 4.25V20.75C24 21.4062 23.7656 21.9219 23.3438 22.3438C22.875 22.8125 22.3594 23 21.75 23H2.25C1.59375 23 1.07812 22.8125 0.65625 22.3438C0.1875 21.9219 0 21.4062 0 20.75V4.25ZM10.875 4.25H2.53125C2.4375 4.25 2.39062 4.29688 2.34375 4.34375C2.25 4.4375 2.25 4.48438 2.25 4.53125V11.375H10.875V4.25ZM2.25 13.625V20.4688C2.25 20.5625 2.25 20.6094 2.34375 20.6562C2.39062 20.75 2.4375 20.75 2.53125 20.75H10.875V13.625H2.25ZM13.125 20.75H21.4688C21.5156 20.75 21.5625 20.75 21.6562 20.6562C21.7031 20.6094 21.75 20.5625 21.75 20.4688V13.625H13.125V20.75ZM21.75 11.375V4.53125C21.75 4.48438 21.7031 4.4375 21.6562 4.34375C21.5625 4.29688 21.5156 4.25 21.4688 4.25H13.125V11.375H21.75Z" fill="black"/>
                            </svg>
                        </Link>
                        <Link to='/porto/rentnow' className='icon-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.125 18.3125C9.03125 18.4531 8.89062 18.5 8.75 18.5C8.5625 18.5 8.46875 18.4531 8.375 18.3125L2.1875 12.125C2.04688 12.0312 2 11.9375 2 11.75C2 11.6094 2.04688 11.4688 2.1875 11.375L8.375 5.1875C8.46875 5.09375 8.5625 5 8.75 5C8.89062 5 9.03125 5.09375 9.125 5.1875L10.0625 6.07812C10.1562 6.21875 10.25 6.35938 10.25 6.5C10.25 6.6875 10.1562 6.78125 10.0625 6.875L6.26562 10.5312H22.4375C22.5781 10.5312 22.7188 10.625 22.8125 10.7188C22.9062 10.8125 23 10.9531 23 11.0938V12.4062C23 12.5938 22.9062 12.7344 22.8125 12.8281C22.7188 12.9219 22.5781 12.9688 22.4375 12.9688H6.26562L10.0625 16.625C10.1562 16.7188 10.25 16.8594 10.25 17C10.25 17.1875 10.1562 17.3281 10.0625 17.4219L9.125 18.3125Z" fill="black"/>
                            </svg>
                        </Link>
                    </div>
                    <div className='data-table'>
                        <div className='dt-item dt-label'><span>PROJECT</span></div>
                        <div className='dt-group dir-col'>
                            <div className='dt-item dt-data'><a href='http://mocktotype.com/' className='link-data'>Mocktotype</a> a simple mockup viewer</div>
                            <div className='dt-group'>
                                <div className='dt-item dt-label'><span>SCOPE</span></div>
                                <div className='dt-item dt-data'>UX, UI, Interaction Design, & Frontend</div>
                            </div>
                        </div>
                        <div className='dt-item dt-data'>2015</div>
                    </div>
                    <div className='right-icon-group'>
                        <Link to='/porto' className='icon-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.75 12.5L19.7656 17.5625C19.9062 17.7031 20 17.8906 20 18.0781C20 18.3125 19.9062 18.5 19.7656 18.5938L18.5938 19.7656C18.4531 19.9531 18.2656 20 18.0781 20C17.8438 20 17.6562 19.9531 17.5625 19.7656L12.5 14.75L7.4375 19.7656C7.29688 19.9531 7.10938 20 6.92188 20C6.6875 20 6.5 19.9531 6.40625 19.7656L5.23438 18.5938C5.04688 18.5 5 18.3125 5 18.0781C5 17.8906 5.04688 17.7031 5.23438 17.5625L10.25 12.5L5.23438 7.4375C5.04688 7.34375 5 7.15625 5 6.92188C5 6.73438 5.04688 6.54688 5.23438 6.40625L6.40625 5.23438C6.5 5.09375 6.6875 5 6.92188 5C7.10938 5 7.29688 5.09375 7.4375 5.23438L12.5 10.25L17.5625 5.23438C17.6562 5.09375 17.8438 5 18.0781 5C18.2656 5 18.4531 5.09375 18.5938 5.23438L19.7656 6.40625C19.9062 6.54688 20 6.73438 20 6.92188C20 7.15625 19.9062 7.34375 19.7656 7.4375L14.75 12.5Z" fill="black"/>
                            </svg>
                        </Link>
                        <Link to='/porto/vcomds' className='icon-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.875 5.1875C15.9688 5.09375 16.0625 5 16.25 5C16.3906 5 16.5312 5.09375 16.625 5.1875L22.8125 11.375C22.9062 11.4688 23 11.6094 23 11.75C23 11.9375 22.9062 12.0312 22.8125 12.125L16.625 18.3125C16.5312 18.4531 16.3906 18.5 16.25 18.5C16.0625 18.5 15.9688 18.4531 15.875 18.3125L14.9375 17.4219C14.7969 17.3281 14.75 17.1875 14.75 17C14.75 16.8594 14.7969 16.7188 14.9375 16.625L18.7344 12.9688H2.5625C2.375 12.9688 2.23438 12.9219 2.14062 12.8281C2.04688 12.7344 2 12.5938 2 12.4062V11.0938C2 10.9531 2.04688 10.8125 2.14062 10.7188C2.23438 10.625 2.375 10.5312 2.5625 10.5312H18.7344L14.9375 6.875C14.7969 6.78125 14.75 6.6875 14.75 6.5C14.75 6.35938 14.7969 6.21875 14.9375 6.07812L15.875 5.1875Z" fill="black"/>
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className='main-content'>
                    <div className='fake-browser'>
                        <svg width="640" height="20" viewBox="0 0 640 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="640" height="20" fill="white"/>
                            <rect width="640" height="14" fill="#F2F2F2"/>
                            <rect y="14" width="640" height="6" fill="white"/>
                            <path d="M32 14H89L86.4276 5.4253C86.1738 4.57934 85.3952 4 84.5119 4H36.4881C35.6048 4 34.8262 4.57934 34.5724 5.4253L32 14Z" fill="white"/>
                            <circle cx="8" cy="7" r="2" fill="#EB5757"/>
                            <circle cx="14" cy="7" r="2" fill="#F2C94C"/>
                            <circle cx="20" cy="7" r="2" fill="#27AE60"/>
                        </svg>
                        <div className='browser-body'>
                            <img src={mock1} className='' />
                        </div>
                    </div>

                    <div className='text-group'>
                        <h6 className='content-title'>BACKGROUND</h6>
                        <p className='content-text'>Back in the day when I was still working at a local digital agency in my hometown, it sometimes became problematic when we tried to share our mockup design to the client. Especially when the client only sees it on their email attachment viewer. Sometimes we ended up with a misunderstanding about the sizing of the design since the client can't see the real sizes accurately.</p>
                        <p className='content-text'>So we thought that maybe we could create a simple product for us to use it as a simple mockup viewer on a real browser. So then the customer will have a better understanding of the whole design from a mockup that we share.</p>
                    </div>
                    <div className='text-group'>
                        <h6 className='content-title'>LOGO</h6>
                    </div>

                    <img src={logoMock} className='content-img' />

                    <div className='text-group'>
                        <h6 className='content-title'>ILLUSTRATION</h6>
                    </div>
                    
                    <div className='img-box'>
                        <img src={mockIllust1} />
                    </div>

                    <div className='img-box flex-box'>
                        <img src={mockIllust2} />
                        <img src={mockIllust3} />
                        <img src={mockIllust4} />
                    </div>

                    <div className='text-group'>
                        <h6 className='content-title'>RESULT</h6>
                    </div>

                    <div className='img-box'>
                        <img src={mock1} />
                        <span>Home page</span>
                    </div>

                    <div className='img-box'>
                        <img src={mock2} />
                        <span>Popup login page</span>
                    </div>

                    <div className='img-box'>
                        <img src={mock3} />
                        <span>Login page</span>
                    </div>

                    <div className='img-box'>
                        <img src={mock4} />
                        <span>Dashboard</span>
                    </div>

                    <div className='img-box'>
                        <img src={mock5} />
                        <span>Dashboard mockup</span>
                    </div>

                    <div className='text-group'>
                        <h6 className='content-title'>CONCLUSION</h6>
                        <p className='content-text'>This product had become one of the top ten products on the product hunt, and gained a quite high visitor overnight because of that. It has 1000 user accounts with merely 5% of active users right now.</p>
                        <p className='content-text'>Unfortunately, we can't figure out how to monetize this product or yet try to develop and maintain this product anymore due to a lot of things each of the creators have to do right now and makes this project less important anymore.</p>
                    </div>
                </div>
                <div className='footer-menu'>
                    <div className='portfolio-menu-group'>
                        <Link to='/porto/rentnow' className='portfolio-menu-item'>
                            <section className='img-box'>
                                <img src={rentporto} />
                            </section>
                            <section className='text-box'>
                                <h6 className='footer-menu-title'>RentNow</h6>
                                <p className='footer-text'>Booking.com app redesign concept</p>
                            </section>
                        </Link>
                        <Link to='/porto/vcomds' className='portfolio-menu-item'>
                            <section className='img-box'>
                                <img src={dsporto} />
                            </section>
                            <section className='text-box'>
                                <h6 className='footer-menu-title'>Antlers Design System</h6>
                                <p className='footer-text'>Creating vaadin.com Design system</p>
                            </section>
                        </Link>
                    </div>
                    <div className='main-menu-bar'>
                        <ul>
                            <li>
                                <Link to='/'>INTRO</Link>
                            </li>
                            <li>
                                <Link to='/about/experience'>ABOUT ME</Link>
                            </li>
                            <li>
                                <Link to='/porto'>MY OTHER WORKS</Link>
                            </li>
                            {/* <li>
                                <Link>GET IN TOUCH</Link>
                            </li> */}
                        </ul>
                        <a className='scroll-top' href='#top-page'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.6875 9.125C5.54687 9.03125 5.5 8.89062 5.5 8.75C5.5 8.5625 5.54687 8.46875 5.6875 8.375L11.875 2.1875C11.9687 2.04687 12.0625 2 12.25 2C12.3906 2 12.5312 2.04687 12.625 2.1875L18.8125 8.375C18.9062 8.46875 19 8.5625 19 8.75C19 8.89062 18.9062 9.03125 18.8125 9.125L17.9219 10.0625C17.7812 10.1562 17.6406 10.25 17.5 10.25C17.3125 10.25 17.2187 10.1562 17.125 10.0625L13.4687 6.26562L13.4687 22.4375C13.4687 22.5781 13.375 22.7187 13.2812 22.8125C13.1875 22.9062 13.0469 23 12.9062 23L11.5937 23C11.4062 23 11.2656 22.9062 11.1719 22.8125C11.0781 22.7187 11.0312 22.5781 11.0312 22.4375L11.0312 6.26562L7.375 10.0625C7.28125 10.1562 7.14062 10.25 7 10.25C6.8125 10.25 6.67187 10.1562 6.57812 10.0625L5.6875 9.125Z" fill="black"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}