import React, { useState } from 'react';
import './my-experience-illustration.scss';

const MyExperienceIllu = props => {
    return (
        <div className={'scene grown ' + props.hoverClass}>
            <div class="big-shadow"></div>
            <div class="background">
                <div class="clock2"></div>
                <div class="windows"></div>
                <div class="book-shelf-2">
                    <div class="box-book"></div>
                    <div class="diagon-book"></div>
                    <div class="verti-book"></div>
                </div>
            </div>
            <div class="all-kid">
                <div class="head">
                    <div class="face">
                        <div class="all-ear">
                            <div class="ear ear-left"></div>
                            <div class="ear ear-right"></div>
                        </div>
                        <div class="top">
                            <div class="hair-all">
                                <div class="hair-tip-1"></div>
                                <div class="hair-tip-2"></div>
                                <div class="hair-1"><div class="hair-2"></div></div>
                                <div class="hair-3"></div>
                            </div>
                        </div>
                        <div class="full-face">
                            <div class="all-eye">
                                <div class="eye eye-left"></div>
                                <div class="eye eye-right"></div>
                            </div>
                            <div class="nose"></div>
                            <div class="mouth"></div>
                        </div>
                    </div>
                </div>
                <div class="neck"></div>
                <div class="all-body">
                    <div class="all-hand">
                        <div class="hand hand-left"></div>
                        <div class="hand hand-right"></div>
                    </div>
                    <div class="body">
                        <div class="neck-tip"></div>
                        <ul class="buttons">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div class="all-colar">
                        <div class="colar colar-left"></div>
                        <div class="colar colar-right"></div>
                    </div>
                </div>

                <div class="foot">
                    <div class="butt"></div>
                    <div class="all-legs">
                        <div class="legs leg-left">
                            <div class="shoes">
                                <div class="shoe-top"></div>
                                <div class="shoe-bottom"></div>
                                <div class="shoe-lace"></div>
                            </div>
                        </div>
                        <div class="legs leg-right">
                            <div class="shoes">
                                <div class="shoe-top"></div>
                                <div class="shoe-bottom"></div>
                                <div class="shoe-lace"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="laptop">
                    <div class="laptop-screen"><div class="logos"><div class="eye-skull"></div></div></div>
                    <div class="base"></div>
                </div>
                <div class="chair">
                    <div class="chair-top"></div>
                    <div class="chair-bottom"></div>
                </div>
            </div>
            <div class="table">
                <div class="top-table"></div>
                <div class="bottom-table"></div>
                <div class="mug"></div>
                <div class="desk-lamp">
                    <div class="lamp-arm"></div>
                    <div class="lamp-base"></div>
                    <div class="lamp"></div>
                </div>
            </div>
            <span className="buble right-tip" data-buble="dion">
                HEY it's me, <br />it's ME !
            </span>
            <span className="buble right-tip" data-buble="clicker">
                Go on,<br />you can click it!
            </span>
        </div>
    );
}

export default MyExperienceIllu;