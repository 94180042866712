import React from 'react';
import './App.scss';
import Main from './layout/Main';
import Header from './layout/Header';
import Navigation from './layout/Navigation';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { class: ''};
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    // this.changeColorClass = this.changeColorClass.bind(this);
  }
  
  toggleMenu() {
    const updateClass = this.state.class === '' ? 'menu-active' : '';
    this.setState({ class: updateClass });
  }

  closeMenu() {
    this.setState({ class: ''});
  }

  // changeColorClass(newColorClass) {
  //   this.setState({ colorClass: newColorClass })
  // }

  render() {
    return (
      <div className={'app ' + this.state.class}>
        <Header toggleMenu={this.toggleMenu} />
        <Main />
        <Navigation closeMenu={this.closeMenu}/>
      </div>
    );
  }
}

export default App;
