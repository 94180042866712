import React from 'react';
import {
    Route,
    useRouteMatch
  } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import Home from '../page/home/Home';
import About from '../page/about/About';
import Porto from '../page/porto/Porto';

export default function Main() {
    let { path, url } = useRouteMatch();
    
    return (
        <div className="main-cage">
            <Route exact path="/">
                {({ match }) => (
                    <CSSTransition in={match != null} timeout={{ appear: 500, enter: 500, exit: 500, }} appear exit classNames="intro-page" unmountOnExit>
                        <Home />
                    </CSSTransition>
                )} 
            </Route>
            <Route path="/about">
                {({ match }) => (
                    <CSSTransition in={match != null} timeout={{ appear: 500, enter: 600, exit: 500, }} appear exit classNames="about-page" unmountOnExit>
                        <About />
                    </CSSTransition>
                )} 
            </Route>
            <Route path="/porto">
                {({ match }) => (
                    <CSSTransition in={match != null} timeout={{ appear: 500, enter: 500, exit: 500, }} appear exit classNames="porto-page" unmountOnExit>
                        <Porto />
                    </CSSTransition>
                )} 
            </Route>
        </div>
    );
}