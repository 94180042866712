import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";
import './about.scss';
import AboutNav from './About-nav';
import AboutMain from './About-main';
import MyExperience from './My-experience';
import MyExperienceIllu from './My-experience-illustration';

export default function About() {

  const [hoverClass, setHoverClass] = useState('');

  function handleHoverClass(hoverClass) {
      setHoverClass(hoverClass);
  }

  function handleRemoveHoverClass() {
      setHoverClass('');
  }
  
  return (
    <div className="about">
      {/* <AboutMain />
      <AboutNav /> */}
      <div className="about-cage">
            <div className="half half-left">
              <div className="cage">
                  <MyExperienceIllu hoverClass={hoverClass} />
              </div>
            </div>
            <div className="half half-right">
              <div className="cage">
                  <MyExperience handleHoverClass={handleHoverClass} handleRemoveHoverClass={handleRemoveHoverClass} />
              </div>
            </div>
        </div>
    </div>
  );
}