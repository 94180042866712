import React from 'react';
import './header.scss';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { width: window.innerWidth };
    }

    componentDidMount() {
        // this.updateWindowSize();
        window.addEventListener('resize', this.updateWindowSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize);
    }

    setBgSize() {
        this.setState({ width: window.innerWidth, height: window.innerWidth });
    }

    render() {
        return (
            <header>
                <Link to='/' className='logo'>
                    <svg width="40" height="49" viewBox="0 0 40 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M20 0H0V40H8V49H10H12H20V45H12V40H20C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM12 32V36H20C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4H4V36H8V32V11V8H20C26.6274 8 32 13.3726 32 20C32 26.6274 26.6274 32 20 32H16V28H20C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12H12V23V28V32Z" fill="black"/>
                    </svg>
                </Link>
                <button onClick={this.props.toggleMenu} className="menu-button">
                    {/* <span id="menu-bg" className="menu-bg" style={{width: this.state.width * 4, height: this.state.width * 4, marginTop: this.state.width * -2, marginLeft: this.state.width * -2}}></span> */}
                    <svg className="menu-icon" width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4.5C0.6875 4.5 0.4375 4.4375 0.25 4.25C0.0625 4.0625 0 3.8125 0 3.5V1C0 0.75 0.0625 0.5 0.25 0.3125C0.4375 0.125 0.6875 0 1 0H17C17.25 0 17.5 0.125 17.6875 0.3125C17.875 0.5 18 0.75 18 1V3.5C18 3.8125 17.875 4.0625 17.6875 4.25C17.5 4.4375 17.25 4.5 17 4.5H1ZM1 14.5C0.6875 14.5 0.4375 14.4375 0.25 14.25C0.0625 14.0625 0 13.8125 0 13.5V11C0 10.75 0.0625 10.5 0.25 10.3125C0.4375 10.125 0.6875 10 1 10H27C27.25 10 27.5 10.125 27.6875 10.3125C27.875 10.5 28 10.75 28 11V13.5C28 13.8125 27.875 14.0625 27.6875 14.25C27.5 14.4375 27.25 14.5 27 14.5H1ZM1 24.5C0.6875 24.5 0.4375 24.4375 0.25 24.25C0.0625 24.0625 0 23.8125 0 23.5V21C0 20.75 0.0625 20.5 0.25 20.3125C0.4375 20.125 0.6875 20 1 20H21C21.25 20 21.5 20.125 21.6875 20.3125C21.875 20.5 22 20.75 22 21V23.5C22 23.8125 21.875 24.0625 21.6875 24.25C21.5 24.4375 21.25 24.5 21 24.5H1Z" fill="black"/>
                    </svg>
                    <svg className="close-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1875 11L21.4375 17.25C21.8125 17.625 22 18.125 22 18.625C22 19.1875 21.8125 19.6875 21.4375 20.0625L20.0625 21.4375C19.625 21.8125 19.125 22 18.625 22C18.0625 22 17.625 21.8125 17.25 21.4375L11 15.1875L4.75 21.4375C4.375 21.8125 3.875 22 3.375 22C2.8125 22 2.3125 21.8125 1.9375 21.4375L0.5625 20.0625C0.1875 19.6875 0 19.1875 0 18.625C0 18.125 0.1875 17.625 0.5625 17.25L6.8125 11L0.5625 4.75C0.1875 4.375 0 3.9375 0 3.375C0 2.875 0.1875 2.375 0.5625 1.9375L1.9375 0.5625C2.3125 0.1875 2.8125 0 3.375 0C3.875 0 4.375 0.1875 4.75 0.5625L11 6.8125L17.25 0.5625C17.625 0.1875 18.0625 0 18.625 0C19.125 0 19.625 0.1875 20.0625 0.5625L21.4375 1.9375C21.8125 2.375 22 2.875 22 3.375C22 3.9375 21.8125 4.375 21.4375 4.75L15.1875 11Z" fill="black"/>
                    </svg>
                </button>
            </header>
        );
    }
}

export default Header;